import { InputType } from "../common/Input/Input";

export interface WidgetInput {
  name: string;
  type: InputType;
  label: string;
  pricePerUnit: number;
  helperText?: string;
  value: number;
}

export const INPUTS: WidgetInput[] = [
  {
    name: "Darbuotojai",
    type: "text",
    label: "Darbuotojai",
    pricePerUnit: 10.0,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Pardavimo sąskaitos",
    label: "Pardavimo sąskaitos",
    pricePerUnit: 0.9,
    helperText: "Cake ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Pirkimo sąskaitos",
    label: "Pirkimo sąskaitos",
    pricePerUnit: 0.9,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Kasos aparatai",
    label: "Kasos aparatai",
    pricePerUnit: 15.0,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Banko operacijų skaičius",
    label: "Banko operacijų skaičius",
    pricePerUnit: 0.2,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Komandiruotės",
    label: "Komandiruotės",
    pricePerUnit: 5.0,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Transporto priemonės",
    label: "Transporto priemonės",
    pricePerUnit: 5.0,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Ilgalaikis turtas",
    label: "Ilgalaikis turtas",
    pricePerUnit: 1.5,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    type: "text",
    name: "Paskolų ir lizingų sutarčių skaičius",
    label: "Paskolų ir lizingų sutarčių skaičius",
    pricePerUnit: 15.0,
    helperText: "Lorem ipsum",
    value: 0,
  },
  {
    name: "checkboxYes",
    type: "checkbox",
    label: "Taip",
    pricePerUnit: 35.0,
    helperText: "",
    value: 0,
  },
  {
    name: "checkboxNo",
    type: "checkbox",
    label: "Ne",
    pricePerUnit: 0,
    helperText: "",
    value: 1,
  },
];

export const VAT_INPUTS: WidgetInput[] = [
  {
    name: "vatCheckboxYes",
    type: "checkbox",
    label: "Taip",
    pricePerUnit: 45.0,
    helperText: "",
    value: 0,
  },
  {
    name: "vatCheckboxNo",
    type: "checkbox",
    label: "Ne",
    pricePerUnit: 0,
    helperText: "",
    value: 1,
  },
];

export const ACTIVITY_INPUTS: WidgetInput[] = [
  {
    name: "activityCheckboxYes",
    type: "checkbox",
    label: "Prekybinė",
    pricePerUnit: 35.0,
    helperText: "",
    value: 1,
  },
  {
    name: "activityCheckboxNo",
    type: "checkbox",
    label: "Paslaugų",
    pricePerUnit: 35.0,
    helperText: "",
    value: 0,
  },
];
